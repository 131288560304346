import { BLOCKS } from "@contentful/rich-text-types"
import { graphql } from "gatsby"
import React, { ReactNode } from "react"
import styled from "styled-components"
import CareerSendCv from "../components/career/career-send-cv.component"
import {
  PrimaryHeading,
  SecondaryHeading,
} from "../components/common/heading.component"
import StandardSection, {
  SmallSection,
} from "../components/common/sections/section.component"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import PageMetadata from "../components/seo/page-metadata.interface"
import useTranslationHook from "../hooks/use-translation.hook"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import BackLink from "../navigation/components/back-link/back-link.component"
import { Menu } from "../navigation/types/menu.interface"
import RichText from "../resolvers/rich-text/rich-text.resolver"
import {
  ContentfulSection,
  RenderRichText,
  AppFunctionComponent,
  TextBuilder,
} from "../types"

interface Props {
  pageContext: {
    city: string
    country: string
    jobType: string
    title: string
    offerContent: RenderRichText
    slug: string
  }
  data: {
    meta: PageMetadata
    cvSection: ContentfulSection
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const CareerOfferHeading = styled(PrimaryHeading)`
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    text-align: center;
  }
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin-bottom: 70px;
  }
`

const CareerDetails = styled.p`
  margin: 0;
  display: inline-flex;
  &:first-of-type {
    &:after {
      content: "";
      border-right: 1px solid ${({ theme }) => theme.color.border};
      margin: 0 10px;
      @media (min-width: ${({ theme }) =>
          theme.breakpoint.mobileHorizontal}px) {
        margin: 0 20px;
      }
    }
  }
`

const CareerDetailsInfo = styled.span`
  font-weight: 500;
  margin-left: 5px;
`

const CareerDescription = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    padding: 0;
    justify-content: initial;
  }
`

const CareerSubHeading = styled(SecondaryHeading)`
  margin: 50px 0 20px 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin: 90px 0 30px 0;
  }
`

const ContentSection = styled(SmallSection)`
  --max-width: 756;
`

const CareerOfferPage: AppFunctionComponent<Props> = ({
  pageContext,
  data: {
    meta,
    cvSection,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  const { city, country, jobType, title, offerContent } = pageContext
  const { description, facebookImage } = meta
  const { translate } = useTranslationHook()

  const careerOfferMeta = {
    title,
    description,
    facebookImage,
  }

  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      masterPage
    >
      <SEO {...careerOfferMeta} title={`Career - ${title}`} />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <StandardSection>
        <LinkContainer>
          <BackLink to="/career/">{translate("career-browse-other")}</BackLink>
        </LinkContainer>
      </StandardSection>
      <ContentSection>
        <CareerOfferHeading renderAs={"h1"}>{title}</CareerOfferHeading>
        <CareerDescription>
          <CareerDetails>
            {translate("career-city")}
            <CareerDetailsInfo>
              {city}, {country}
            </CareerDetailsInfo>
          </CareerDetails>
          <CareerDetails>
            {translate("career-type")}
            <CareerDetailsInfo>{jobType}</CareerDetailsInfo>
          </CareerDetails>
        </CareerDescription>
        {offerContent && (
          <RichText
            content={offerContent}
            options={{
              [BLOCKS.HEADING_2]: (_, children: ReactNode) => (
                <CareerSubHeading renderAs={"h2"}>{children}</CareerSubHeading>
              ),
            }}
          />
        )}
      </ContentSection>
      <CareerSendCv content={cvSection} />
    </Layout>
  )
}

export default CareerOfferPage

export const query = graphql`
  query CareerOfferPage($language: String!, $locale: String!) {
    meta: contentfulPageMeta(page: { eq: "career/offer" }) {
      ...PageMeta
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cvSection: contentfulSection(
      contentfulid: { eq: "career/send-cv" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      sectionElement {
        text {
          raw
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`
